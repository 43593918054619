<template>
  <div>
    <base-modal :showing="visible" size="max-w-4xl" @close="$emit('close')" @opened="onOpened">
      <base-card :with-style="false" title="Detail Stock" description="Berdasarkan data stock yang telah dibuat">
        <div class="border-t pt-6">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.product_code }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.product_name }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Satuan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product ? product.attributes.unit_name : '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Isi</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ product ? product.attributes.volume : '-' }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Point Value (PV)</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ (product ? product.attributes.point : 0) | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Bonus Value (PV)</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ (product ? product.attributes.bonus : 0) | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Total Berat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ (product ? product.attributes.weight : 0) | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stok</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.stock_real | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Stok Restock</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ stock.data.attributes.stock_loan_qty | toCurrency }}
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Order</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    stock.data.attributes.is_orderable ? 'blue' : 'yellow'
                  "
                >
                  {{
                    stock.data.attributes.is_orderable ? 'Open' : 'Close'
                  }}</base-badge
                >
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Status Pre Order</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <base-badge
                  :color="
                    stock.data.attributes.is_pre_orderable
                      ? 'blue'
                      : 'yellow'
                  "
                >
                  {{
                    stock.data.attributes.is_pre_orderable ? 'Open' : 'Close'
                  }}</base-badge
                >
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Distributor</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ (product ? product.attributes.supplier_price : 0) | toCurrency }}</dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Stockist</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ getProductPriceByBuyerType('Stockist')?.attributes?.price | toCurrency }}</dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-bold text-gray-700">Harga Konsumen</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ getProductPriceByBuyerType('Konsumen')?.attributes?.price | toCurrency }}</dd>
            </div>
            <div class="sm:col-span-6">
              <dt class="text-sm font-bold text-gray-700">Riwayat Stock Oktober 2024</dt>
              <dd class="mt-1">
                <stock-history-table :data="stockHistories" @change-page="onChangePageHistory" />
              </dd>
            </div>
          </dl>
        </div>
      </base-card>
    </base-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import StockHistoryTable from './stock-history-table.vue';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    StockHistoryTable
  },
  props: {
    visible: Boolean,
    stockId: String
  },
  emits: ['close'],
  data() {
    return {
      loadingStock: false,
      loadingStockHistory: false,
      loadingProductPrice: false,
      stock: {
        data: {
          attributes: {},
          relationships: {
            product: {
              data: {}
            }
          }
        },
        included: []
      },
      productPrices: {
        data: []
      },
      stockHistories: {
        data: [],
        meta: {
          page: {}
        }
      }
    }
  },
  computed: {
    loading() {
      return this.loadingStock || this.loadingStockHistory || this.loadingProductPrice
    },
    product() {
      return this.getSingleIncluded(this.stock, this.stock.data.relationships.product.data.id)
    }
  },
  methods: {
    getProductPriceByBuyerType(buyerType) {
      return this.productPrices.data.find(productPrice => productPrice.attributes['buyer-type'] === buyerType)
    },
    async loadProductPrices() {
      this.loadingProductPrice = true

      const [res, err] = await this.request(`/api/v1/products/${this.product.id}/product-prices`, {
        params: {
          'fields[product-prices]': 'buyer-type,price',
          'filter[buyer-type-in]': 'Stockist,Konsumen'
        }
      })

      if (!err) {
        this.productPrices = res
      }

      this.loadingProductPrice = false
    },
    async loadStockHistories(params) {
      this.loadingStockHistory = true

      const [res, err] = await this.request(`/api/v1/stocks/${this.stock.data.id}/stock-trxes`, {
        params: {
          'page[size]': 5,
          'fields[stock-trxes]': 'createdAt,amount,type,current_stock,description',
          ...params
        }
      })

      if (!err) {
        this.stockHistories = res
      }

      this.loadingStockHistory = false
    },
    onChangePageHistory(page) {
      this.loadStockHistories({
        'page[number]': page
      })
    },
    async onOpened() {
      this.loadingStock = true

      const [res, err] = await this.request(`/api/v1/stocks/${this.stockId}`, {
        params: {
          'include': 'product.product-prices',
          'fields[stocks]': 'product_code,product_name,stock_real,stock_loan_qty,product,is_orderable,is_pre_orderable',
          'fields[products]': 'unit_name,volume,point,bonus,weight,supplier_price'
        }
      })

      if (!err) {
        this.stock = res

        this.loadProductPrices()
        this.loadStockHistories()
      }

      this.loadingStock = false
    }
  }
}
</script>
