<template>
  <div class="flex mx-auto flex-col gap-y-6 px-4 py-8 sm:px-4 lg:px-8">
    <!-- Replace with your content -->
    <h2 class="text-2xl text-black">
      Selamat Datang, <span class="font-bold">{{ name }}</span>
    </h2>
    <div class="w-full">
      <!--Metric Card-->
      <div
        class="flex w-full flex-row justify-between overflow-hidden rounded-lg border-b-4 border-yellow-600 bg-yellow-50"
      >
        <div class="flex flex-col justify-between p-5">
          <p class="font-normal text-yellow-800">
            Kami di sini untuk membantu. Beri tahu kami masalah apa yang Anda
            alami dan kami akan menyelesaikannya.
            <span class="text-green-500"><i class="fas fa-caret-up"></i></span>
          </p>
          <div>
            <button
              type="button"
              class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm transition hover:bg-green-700 hover:text-base focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <WhatsappIcon class="-ml-1 mr-2 h-5 w-5" />
              Hubungi Kami
            </button>
          </div>
        </div>
        <div class="relative h-60">
          <ManUseLaptop class="absolute bottom-0 right-0" />
        </div>
      </div>
      <!--/Metric Card-->
    </div>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div v-for="stat in rangkuman" :key="stat.name">
        <cardStat :data="stat" />
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2">
      <ul
        role="list"
        class="divide-y divide-gray-200 rounded-lg bg-white px-4 py-5 shadow sm:p-6"
      >
        <h2 class="mb-4 text-2xl font-bold text-black">
          {{ getOrders.data.length }}
          <span class="font-normal text-gray-400">transaksi terakhir</span>
        </h2>
        <li class="flex flex-col gap-y-1 py-4">
          <div
            class="flex-1 cursor-pointer rounded border-b border-gray-200 py-2 transition-all duration-200 ease-in-out hover:bg-gray-100 hover:p-3 sm:hover:p-1.5"
            v-for="item in getOrders.data"
            :key="item.id"
            @click="openTransaction(item)"
          >
            <div class="flex items-center justify-between">
              <h3 class="text-sm">
                Dari
                <span class="font-bold">{{ item.attributes.origin_code }}</span>
                →
                <span class="font-bold">{{
                  item.attributes.destination_code
                }}</span>
              </h3>
              <p class="text-sm text-gray-500">
                {{ item.attributes.final_price | toCurrency }}
              </p>
            </div>
            <p class="text-sm text-gray-500">
              {{ dayjs(item.attributes.createdAt).format('ll LT') }}
            </p>
          </div>
        </li>
      </ul>
      <ul
        role="list"
        class="divide-y divide-gray-200 rounded-lg bg-white px-4 py-5 shadow sm:p-6"
      >
        <h2 class="mb-4 text-2xl font-bold text-black">
          {{ getStockMovements.data.length }}
          <span class="font-normal text-gray-400">pengiriman terakhir</span>
        </h2>
        <li class="flex flex-col gap-y-1 py-4">
          <div
            class="flex-1 rounded border-b border-gray-200 py-2 transition-all duration-200 ease-in-out hover:bg-gray-100 hover:p-3 sm:hover:p-1.5"
            v-for="item in getStockMovements.data"
            :key="item.id"
          >
            <div class="flex items-center justify-between">
              <h3 class="text-sm font-normal">
                Kemasan
                <span class="font-bold">{{ item.attributes.code }}</span>
                transaksi
                <span class="font-bold">{{
                  getRelationships(
                    getStockMovements,
                    item.relationships['order'].data.id
                  ).attributes.origin_code
                }}</span>
              </h3>
              <div class="whitespace-nowrap text-sm text-gray-500">
                <span
                  v-if="item.attributes.is_shipped"
                  class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                >
                  Terkirim
                </span>
                <span
                  v-else
                  class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                >
                  Belum dikirim
                </span>
              </div>
            </div>
            <p class="text-sm text-gray-500">
              {{ dayjs(item.attributes.createdAt).format('ll LT') }}
            </p>
          </div>
        </li>
      </ul>
    </div>

    <!-- Modal -->
    <BaseModal
      :showing="detailLatestTransaction"
      @close="detailLatestTransaction = false"
      :showClose="detailLatestTransaction"
      :backgroundClose="true"
      size="max-w-xl"
    >
      <h2 id="summary-heading" class="text-lg font-medium text-gray-900">
        Ringkasan Transaksi
      </h2>
      <dl class="mt-6 space-y-4">
        <div class="flex items-center justify-between">
          <dt class="text-sm text-gray-600">Kode Transaksi</dt>
          <dd class="text-sm font-bold text-gray-900">
            {{ dataTransaction.origin_code }}
          </dd>
        </div>
        <div class="flex items-center justify-between">
          <dt class="text-sm text-gray-600">Kode Tujuan</dt>
          <dd class="text-sm font-bold text-gray-900">
            {{ dataTransaction.destination_code }}
          </dd>
        </div>
        <div class="flex items-center justify-between">
          <dt class="text-sm text-gray-600">Status Gudang</dt>
          <dd class="text-sm font-bold text-gray-900">
            {{ dataTransaction.warehouse_status }}
          </dd>
        </div>
        <div class="flex items-center justify-between">
          <dt class="text-sm text-gray-600">Status Pengiriman</dt>
          <dd class="text-sm font-bold text-gray-900">
            {{ dataTransaction.order_shipment }}
          </dd>
        </div>
        <div class="flex items-center justify-between">
          <dt class="text-sm text-gray-600">Status Transaksi</dt>
          <dd class="text-sm font-bold text-gray-900">
            {{ dataTransaction.order_status }}
          </dd>
        </div>
        <div
          class="flex items-center justify-between border-t border-gray-200 pt-4"
        >
          <dt class="text-base font-medium text-gray-900">Status Pengiriman</dt>
          <dd class="text-base font-bold text-gray-900">
            {{ dataTransaction.final_price | toCurrency }}
          </dd>
        </div>
      </dl>
    </BaseModal>
    <!-- /End replace -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import cardStat from '@/components/base/cardStat.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import { MoneyIcon, TransactionIcon, DeliveryIcon } from '@/assets/icons';
import { ManUseLaptop } from '@/assets/images';
import { WhatsappIcon } from '@/assets/icons';
import dayjs from 'dayjs';

export default {
  components: {
    cardStat,
    ManUseLaptop,
    BaseModal,
    WhatsappIcon,
  },

  data() {
    const destination_warehouse_id = StorageService.getUser().current_warehouse;

    return {
      destination_warehouse_id,
      detailLatestTransaction: false,
      detailLatestDelivery: false,
      dataTransaction: {},
      name: StorageService.getUser().name,
      rangkuman: [
        {
          title: null,
          value: null,
          src: null,
        },
      ],
      latestTransaction: [
        {
          origin_code: 'Dari AB.001SO1 ke N-2PO1',
          destination_code: 'Dari AB.001SO1 ke N-2PO1',
          date: '5 Okt 2022 09.58',
          value: 1040000,
        },
      ],
      dashboardStats: StorageService.getDashboardStats(),
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/getLoading',
      getOrders: 'orders/getOrders',
      getStockMovements: 'stock_movements/getStockMovements',
      me: 'auth/getUser'
    }),
  },
  mounted() {
    this.rangkuman = [
      {
        title: 'Transaksi Belum Selesai',
        value: this.dashboardStats.sale_incompleted,
        icon: TransactionIcon,
      },
      {
        title: 'Transaksi Belum Dibayar',
        value: this.dashboardStats.sale_unpaid,
        icon: MoneyIcon,
      },
      {
        title: 'Transaksi Belum Dikirim',
        value: this.dashboardStats.sale_unsend,
        icon: DeliveryIcon,
      },
      // {
      //   title: 'Mitra Usaha Belum Aktif',
      //   value: this.dashboardStats.member_inactive,
      //   icon: MemberIcon,
      // },
    ];
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchStockMovements: 'stock_movements/fetchStockMovements',
    }),
    openTransaction(data) {
      this.detailLatestTransaction = !this.detailLatestTransaction;
      this.dataTransaction.origin_code = data.attributes.origin_code;
      this.dataTransaction.destination_code = data.attributes.destination_code;
      this.dataTransaction.order_shipment = data.attributes.order_shipment;
      this.dataTransaction.order_status = data.attributes.order_status;
      this.dataTransaction.warehouse_status = data.attributes.warehouse_status;
      this.dataTransaction.final_price = data.attributes.final_price;
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
  created() {
    this.fetchOrders({
      'page[limit]': 5,
      'filter[destination_office_category_id]': 5,
      'filter[order_type]': 'sale',
      'filter[origin_warehouse_id]': this.me.current_warehouse
    });
    this.fetchStockMovements({
      'page[limit]': 5,
      'filter[destination_office_category_id]': 5,
      'filter[origin_office_id]': this.me.office_id
    });
  },
};
</script>
