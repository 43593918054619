var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-input',{attrs:{"type":"search","shadow":false,"placeholder":"Cari Kode","debounce":""},on:{"native-input":_vm.loadReports},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('base-select',{attrs:{"expand":false,"shadow":false,"options":[
          { key: 'all', label: 'Status Faktur', value: null },
          { key: 'draft', label: 'Draft', value: false },
          { key: 'valid', label: 'Valid', value: true },
        ]},on:{"change":_vm.loadReports},model:{value:(_vm.filter.is_valid),callback:function ($$v) {_vm.$set(_vm.filter, "is_valid", $$v)},expression:"filter.is_valid"}}),_c('base-select',{attrs:{"expand":false,"shadow":false,"options":[
          { key: 'all', label: 'Status Laporan', value: null },
          { key: 'draft', label: 'Belum Diverifikasi', value: false },
          { key: 'valid', label: 'Diverifikasi Pusat', value: true },
        ]},on:{"change":_vm.loadReports},model:{value:(_vm.filter.is_verified),callback:function ($$v) {_vm.$set(_vm.filter, "is_verified", $$v)},expression:"filter.is_verified"}}),_c('base-button',{attrs:{"to":{ name: 'penjualan-pbs.tambah' }}},[_vm._v("Tambah Laporan Penjualan P3S")])],1),_c('datatable',{attrs:{"scroll-x":false,"columns":_vm.tableColumns,"total":_vm.reports.meta.page.total,"perPage":_vm.reports.meta.page.perPage,"currentPage":_vm.reports.meta.page.currentPage},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.reports.data),function(report,index){return _c('tr',{key:index,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){return _vm.onView(report)}}},[_c('td',{class:classes.td},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(report.attributes.code)+" ")])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm._f("formatDate")(report.attributes.createdAt))+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(report.attributes.name)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(report.attributes.total_price))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(report.attributes.total_payment))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":report.attributes.valid ? 'green' : 'yellow'}},[_vm._v(_vm._s(report.attributes.valid ? 'Valid' : 'Draft'))])],1),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":report.attributes.verified ? 'indigo' : 'yellow'}},[_vm._v(_vm._s(report.attributes.verified ? 'Diverifikasi Pusat' : 'Belum Diverifikasi'))])],1)])})}}])})],1),_c('view-modal',{attrs:{"visible":_vm.viewModal.visible,"id":_vm.viewModal.id},on:{"close":function($event){_vm.viewModal.visible = false},"validated":_vm.onValidated}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }