<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input
          type="search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          v-model="filter.search"
          @native-input="loadStockLoanMovements"
        />
        <base-select
          :expand="false"
          :shadow="false"
          :options="[
            { key: 'all', label: 'Status Faktur', value: null },
            { key: 'draft', label: 'Draft', value: false },
            { key: 'valid', label: 'Valid', value: true },
          ]"
          v-model="filter.is_valid"
          @change="loadStockLoanMovements"
        />
        <base-select
          :expand="false"
          :shadow="false"
          :options="[
            { key: 'all', label: 'Status Laporan', value: null },
            { key: 'draft', label: 'Belum Dilaporkan', value: false },
            { key: 'valid', label: 'Dilaporkan', value: true },
          ]"
          v-model="filter.is_reported"
          @change="loadStockLoanMovements"
        />
        <base-button :to="{ name: 'penjualan-pbs.tambah' }"
          >Tambah Penjualan P3S</base-button
        >
      </div>

      <datatable
        :scroll-x="false"
        :columns="tableColumns"
        :total="stockLoanMovements.meta.page.total"
        :perPage="stockLoanMovements.meta.page.perPage"
        :currentPage="stockLoanMovements.meta.page.currentPage"
        @pagechanged="onPageChanged"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="(stockLoanMovement, index) in stockLoanMovements.data"
            :key="index"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onView(stockLoanMovement)"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ stockLoanMovement.attributes.code }}
              </p>
            </td>
            <td :class="classes.td">
              {{ stockLoanMovement.attributes.createdAt | formatDate }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ stockLoanMovement.attributes.items_qty | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ stockLoanMovement.attributes.total_price | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :color="stockLoanMovement.attributes.valid ? 'green' : 'yellow'"
                >{{
                  stockLoanMovement.attributes.valid ? 'Valid' : 'Draft'
                }}</base-badge
              >
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :color="stockLoanMovement.attributes.reported ? 'indigo' : 'yellow'"
                >{{
                  stockLoanMovement.attributes.reported ? 'Dilaporkan' : 'Belum Dilaporkan'
                }}</base-badge
              >
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-modal
      :visible="viewModal.visible"
      :stock-loan-movement-id="viewModal.id"
      @close="viewModal.visible = false"
      @edit="
        $router.push({
          name: 'penjualan-pbs.edit',
          params: { id: viewModal.id },
        })
      "
      @validated="onValidated"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import ViewModal from '@/components/stock-loan-movement/view-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewModal },
  data() {
    return {
      filter: {
        search: null,
        is_valid: null,
        is_reported: null
      },
      loading: false,
      stockLoanMovements: {
        data: [],
        meta: {
          page: {},
        },
      },
      viewModal: {
        visible: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'code', name: 'No Penjualan' },
        { id: 'date', name: 'Tanggal' },
        { id: 'qty', name: 'Total Barang', theadClass: 'text-right' },
        { id: 'price', name: 'Total Harga', theadClass: 'text-right' },
        { id: 'invoice_status', name: 'Status', theadClass: 'text-center' },
        { id: 'report_status', name: 'Status', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    async loadStockLoanMovements(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}/stock-loan-movements`,
        {
          params: {
            'page[size]': 5,
            'filter[is_valid]': this.filter.is_valid,
            'filter[is_reported]': this.filter.is_reported,
            'filter[search]': this.filter.search,
            'fields[stock-loan-movements]': 'code,createdAt,items_qty,total_price,valid,reported',
            sort: '-createdAt',
            ...params,
          },
        }
      );

      if (!err) {
        this.stockLoanMovements = res;
      }

      this.loading = false;
    },
    onDeleted() {
      this.loadStockLoanMovements();

      this.viewModal.visible = false;
    },
    onPageChanged(page) {
      this.loadStockLoanMovements({
        'page[number]': page,
      });
    },
    onValidated() {
      this.loadStockLoanMovements();

      this.viewModal.visible = false;
    },
    onView(stockLoanMovement) {
      this.viewModal.id = stockLoanMovement.id;
      this.viewModal.visible = true;
    },
  },
  created() {
    this.loadStockLoanMovements();
  },
};
</script>
