<template>
  <base-wrapper :loading="loading">
    <div class="space-y-6">
      <base-card title="Detail Laporan">
        <div class="grid gap-4 sm:grid-cols-2">
          <base-input
            inset
            label="Nama Laporan"
            placeholder="Laporan 1"
            type="text"
            v-model="form.name"
          />
          <base-input
            inset
            label="Deskripsi Laporan"
            placeholder="Deskripsi Laporan 1"
            type="text"
            v-model="form.description"
          />
        </div>
      </base-card>

      <base-card title="Daftar Penjualan P3S">
        <div class="space-y-4">
          <div
            v-if="form.items.length"
            class="grid items-center justify-between sm:grid-cols-6"
          >
            <p class="text-sm font-semibold text-green-500">
              {{ form.items.length }} dipilih
              {{
                stockLoanMovements.meta.page.lastPage > 1
                  ? 'di semua halaman'
                  : ''
              }}
            </p>
            <button
              class="flex items-center justify-end gap-2 text-sm font-semibold text-red-500"
              @click="onResetFormItems"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              Hapus Pilihan
            </button>
            <div></div>
            <div></div>
            <p class="text-right text-sm font-bold text-gray-900">Total</p>
            <p class="text-right text-sm text-gray-900">
              {{ totalItemsPrice | toCurrency }}
            </p>
          </div>
          <datatable
            :scroll-x="false"
            :columns="[
              { id: 'check', name: '', theadStyle: { width: '10px' } },
              { id: 'code', name: 'No Penjualan' },
              { id: 'total_price', name: 'Total Harga' },
            ]"
            :total="stockLoanMovements.meta.page.total"
            :perPage="stockLoanMovements.meta.page.perPage"
            :currentPage="stockLoanMovements.meta.page.currentPage"
            @pagechanged="onStockLoanMovementsPagechanged"
          >
            <template #tbody="{ classes }">
              <tr
                v-for="stockLoanMovement in stockLoanMovements.data"
                :key="stockLoanMovements.id"
                :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                @click="onCheckItem(stockLoanMovement)"
              >
                <td :class="classes.td">
                  <base-checkbox
                    :with-label="false"
                    :input-checked="formItemIds.includes(stockLoanMovement.id)"
                  />
                </td>
                <td :class="classes.td">
                  <p class="font-bold text-gray-900">
                    {{ stockLoanMovement.attributes.code }}
                  </p>
                </td>
                <td :class="[classes.td]">
                  {{ stockLoanMovement.attributes.total_price | toCurrency }}
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </base-card>

      <create-payment-form
        v-if="pusat.id && stockLoanMovements.meta.page.total"
        :office-id="pusat.id"
        :with-validate-button="false"
        :min-amount="totalItemsPrice"
        sync
        v-model="form.payments"
      />

      <div class="flex justify-end gap-2">
        <base-button :disabled="!validSubmit" @click="onSubmit"
          >Simpan</base-button
        >
        <base-button color="white" :to="{ name: 'laporan-penjualan-pbs' }"
          >Kembali</base-button
        >
      </div>
    </div>

    <view-modal
      :id="viewModal.id"
      :visible="viewModal.visible"
      @close="$router.push({ name: 'laporan-penjualan-pbs' })"
      @validated="$router.push({ name: 'laporan-penjualan-pbs' })"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import CreatePaymentForm from '@/components/payment/create-payment-form.vue';
import ViewModal from '@/components/stock-loan-movement-report/view-modal.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: { BaseCheckbox, CreatePaymentForm, ViewModal },
  data() {
    return {
      loadingStockLoanMovements: false,
      loadingPusat: false,
      loadingStockLoanReport: false,
      form: {
        name: null,
        description: null,
        items: [],
        payments: [
          {
            paymentMethodTypeId: null,
            amount: null,
            officeBankId: null,
            receiptNumber: null,
          },
        ],
      },
      stockLoanMovements: {
        data: [],
        meta: {
          page: {},
        },
      },
      pusat: {
        id: null,
      },
      viewModal: {
        id: null,
        visible: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    validSubmit() {
      return (
        this.form.items.length && this.totalPayment === this.totalItemsPrice
      );
    },
    loading() {
      return (
        this.loadingStockLoanMovements ||
        this.loadingPusat ||
        this.loadingStockLoanReport
      );
    },
    formItemIds() {
      return this.form.items.map((item) => item.id);
    },
    totalItemsPrice() {
      return this.form.items.reduce(
        (total, item) => total + item.totalPrice,
        0
      );
    },
    totalPayment() {
      return this.form.payments.reduce((total, item) => {
        const amount = item.amount ? Number(item.amount.replace(/\D/g, '')) : 0;

        return total + amount;
      }, 0);
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadPusat() {
      this.loadingPusat = true;

      const [res, err] = await this.request(`/api/v1/offices`, {
        params: {
          'filter[office_category_id]': 1,
          'filter[code]': 'PUSAT',
          'fields[simple-offices]': 'office_id',
        },
      });

      if (!err) {
        this.pusat.id = res.data[0].attributes.office_id;
      }

      this.loadingPusat = false;
    },
    async loadStockLoanMovements(params) {
      this.loadingStockLoanMovements = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}/stock-loan-movements`,
        {
          params: {
            'page[size]': 5,
            'filter[is_reported]': false,
            'fields[stock-loan-movements]': 'code,total_price',
            ...params,
          },
        }
      );

      if (!err) {
        this.stockLoanMovements = res;
      }

      this.loadingStockLoanMovements = false;
    },
    onCheckItem(stockLoanMovement) {
      const index = this.form.items.findIndex(
        (item) => item.id === stockLoanMovement.id
      );

      if (index !== -1) {
        this.form.items.splice(index, 1);
      } else {
        this.form.items.push({
          id: stockLoanMovement.id,
          totalPrice: stockLoanMovement.attributes.total_price,
        });
      }
    },
    onStockLoanMovementsPagechanged(page) {
      this.loadStockLoanMovements({ 'page[number]': page });
    },
    async onSubmit() {
      this.loadingStockLoanReport = true;

      const [res, err] = await this.request(
        '/api/v1/stock-loan-movement-reports/-actions/store',
        {
          method: 'post',
          data: {
            name: this.form.name,
            description: this.form.description,
            items: this.formItemIds,
            payments: this.form.payments.map((payment) => ({
              payment_method_type_id: payment.paymentMethodTypeId,
              amount: Number(payment.amount.replace(/\D/g, '')),
              office_bank_id: payment.officeBankId,
              receipt_number: payment.receiptNumber,
            })),
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.viewModal.id = res.data.id;
        this.viewModal.visible = true;
      }

      this.loadingStockLoanReport = false;
    },
    onResetFormItems() {
      this.form.items = [];
    },
  },
  created() {
    this.loadPusat();
    this.loadStockLoanMovements();
  },
};
</script>
