<template>
  <base-wrapper>
    <div class="space-y-6">
      <create-order-form
        with-origin-office
        :with-origin-office-type="!order.id"
        :with-destination-office="false"
        :with-restock-type="false"
        :with-loan="false"
        :valid="order.valid"
        use-origin-office-warehouse
        v-model="order"
      />

      <template v-if="order.originOffice.id">
        <create-order-detail-form
          :origin-office="order.originOffice"
          :origin-warehouse="order.originOffice.currentWarehouse"
          :destination-office="order.destinationOffice"
          :buyer-type="order.buyerType"
          :area="order.area"
          :order-id="order.id"
          :create-order-attributes="{
            order_shipment: order.shipment_type,
            ...(order.custom_shipment_address
              ? {
                  destination_address: {
                    province_id: order.shipmentAddress.province.id,
                    city_id: order.shipmentAddress.city.id,
                    district_id: order.shipmentAddress.district.id,
                    village_id: order.shipmentAddress.village.id,
                    address: order.shipmentAddress.address,
                  },
                }
              : {}),
          }"
          custom-store-order-path="/api/v1/orders/-actions/restock-loan-stockist"
          :disabled="order.valid"
          v-model="orderDetails"
          @order-created="onOrderCreated"
        />

        <base-card title="Metode Pembayaran">
          <base-input
            v-if="order.valid"
            fullwidth
            inset
            label="Saldo Restock"
            disabled
            placeholder="1.000.000"
            :value="totalPrice | toCurrency"
          />
          <div
            v-else
            :class="[
              'grid gap-4',
              balanceRemainder > 0 ? 'sm:grid-cols-3' : 'sm:grid-cols-2',
            ]"
          >
            <base-input
              fullwidth
              inset
              label="Saldo Restock"
              disabled
              placeholder="1.000.000"
              :value="order.destinationOffice.restock_balance | toCurrency"
            />
            <base-input
              fullwidth
              inset
              label="Nominal"
              disabled
              placeholder="1.000.000"
              :value="totalPrice | toCurrency"
            />
            <base-input
              v-if="balanceRemainder > 0"
              fullwidth
              inset
              label="Kekurangan"
              disabled
              placeholder="1.000.000"
              :value="Math.abs(balanceRemainder) | toCurrency"
            />
          </div>
        </base-card>
      </template>

      <div class="flex justify-end gap-2">
        <base-button
          v-if="!order.valid"
          :disabled="!canValidate"
          @click="onValidate"
          >Siap Validasi</base-button
        >
        <base-button color="white" :to="{ name: 'restock-pbs' }"
          >Kembali</base-button
        >
      </div>
    </div>

    <validate-order-confirmation
      :visible="validateConfirmationVisible"
      :order-id="order.id"
      @close="validateConfirmationVisible = false"
      @confirmed="onConfirmValidate"
    />
    <view-order-modal
      :visible="viewModalVisible"
      :order-id="order.id"
      :columns="[
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'address',
      ]"
      @close="$router.push({ name: 'restock-pbs' })"
      @validated="$router.push({ name: 'restock-pbs' })"
      @deleted="$router.push({ name: 'restock-pbs' })"
    />

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import CreateOrderForm from '@/components/order/create-order-form.vue';
import CreateOrderDetailForm from '@/components/order/create-order-detail-form.vue';
import { mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import ValidateOrderConfirmation from '@/components/order/validate-order-confirmation.vue';
import ViewOrderModal from '@/components/order/view-order-modal.vue';

export default {
  components: {
    CreateOrderForm,
    CreateOrderDetailForm,
    ValidateOrderConfirmation,
    ViewOrderModal,
  },
  mixins: [requestMixin],
  data() {
    return {
      loadingBuyerType: false,
      loadingDestinationOffice: false,
      loadingOriginOffice: false,
      loadingOrder: false,
      order: {
        id: null,
        area: {
          id: null,
          code: null,
        },
        buyerType: {
          id: null,
          code: null,
        },
        custom_shipment_address: false,
        date: new Date(),
        destinationOffice: {
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,
          restock_balance: null,

          address: null,

          currentWarehouse: {
            id: null,
            code: null,
          },

          area: {
            id: null,
            code: null,
          },
        },
        originOfficeType: null,
        originOffice: {
          id: null,
          code: null,
          originalCode: null,
          searchCode: null,
          name: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
          area: {
            id: null,
            code: null,
          },
        },
        shipment_type: 'pickup',
        shipmentAddress: {
          province: {
            id: null,
            name: null,
          },
          city: {
            id: null,
            name: null,
          },
          district: {
            id: null,
            name: null,
          },
          village: {
            id: null,
            name: null,
          },
          address: null,
          area: {
            id: null,
            code: null,
          },
        },
        valid: false,
      },
      orderDetails: [
        {
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
        },
      ],
      validateConfirmationVisible: false,
      viewModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    balanceRemainder() {
      return this.totalPrice - this.order.destinationOffice.restock_balance;
    },
    canValidate() {
      return this.orderDetails.filter((orderDetail) => orderDetail.qty > 0)
        .length;
    },
    loading() {
      return (
        this.loadingDestinationOffice ||
        this.loadingOriginOffice ||
        this.loadingBuyerType ||
        this.loadingOrder
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
  },
  methods: {
    async loadBuyerType() {
      this.loadingBuyerType = true;

      const [res, err] = await this.request('/api/v1/buyer-types', {
        params: {
          'filter[code]': 10,
          'fields[buyer-types]': 'code',
        },
      });

      if (!err && res.data.length) {
        const buyerType = res.data[0];

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;
      }

      this.loadingBuyerType = false;
    },
    async loadDestinationOffice() {
      this.loadingDestinationOffice = true;

      const [office, err] = await this.request(
        `/api/v1/offices/${this.me.office_id}`,
        {
          params: {
            include: 'current-warehouse,area,stockist-loan',
            'fields[offices]':
              'code,name,address,current-warehouse,area,stockist-loan',
            'fields[warehouses]': 'code,name',
            'fields[areas]': 'code,name',
            'fields[stockist-loans]': 'amount',
          },
        }
      );

      if (!err) {
        this.order.destinationOffice.id = office.data.id;
        this.order.destinationOffice.code = office.data.attributes.code;
        this.order.destinationOffice.searchCode = office.data.attributes.code;
        this.order.destinationOffice.originalCode = office.data.attributes.code;
        this.order.destinationOffice.name = office.data.attributes.name;
        this.order.destinationOffice.address = office.data.attributes.address;

        const warehouse = this.getSingleIncluded(
          office,
          office.data.relationships['current-warehouse'].data.id
        );

        this.order.destinationOffice.currentWarehouse.id = warehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          warehouse.attributes.code;

        const area = this.getSingleIncluded(
          office,
          office.data.relationships.area.data.id
        );

        this.order.destinationOffice.area.id = area.id;
        this.order.destinationOffice.area.code = area.attributes.code;

        const stockistLoan = this.getSingleIncluded(
          office,
          office.data.relationships['stockist-loan'].data.id
        );

        this.order.destinationOffice.restock_balance =
          stockistLoan.attributes.amount;
      }

      this.loadingDestinationOffice = false;
    },
    async loadOrder() {
      this.loadingOrder = true;

      const [order, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}`,
        {
          params: {
            include:
              'area,buyer-type,destination-office.stockist-loan,destination-warehouse,origin-office,origin-warehouse,order-details',
            'fields[orders]':
              'createdAt,order_shipment,is_valid_for_payment,area,buyer-type,destination-office,destination-warehouse,origin-office,origin-warehouse,order-details,shipment_address',
            'fields[areas]': 'code',
            'fields[offices]': 'code,name,address,stockist-loan,office_type,area_code',
            'fields[stockist-loans]': 'amount',
            'fields[warehouses]': 'code,name',
            'fields[order-details]':
              'product_code,product_name,current_stock,product_qty,product_price,total_price',
          },
        }
      );

      if (!err) {
        this.order.id = order.data.id;

        const area = this.getSingleIncluded(
          order,
          order.data.relationships.area.data.id
        );
        const buyerType = this.getSingleIncluded(
          order,
          order.data.relationships['buyer-type'].data.id
        );
        const destinationOffice = this.getSingleIncluded(
          order,
          order.data.relationships['destination-office'].data.id
        );
        const destinationWarehouse = this.getSingleIncluded(
          order,
          order.data.relationships['destination-warehouse'].data.id
        );
        const originOffice = this.getSingleIncluded(
          order,
          order.data.relationships['origin-office'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          order,
          order.data.relationships['origin-warehouse'].data.id
        );

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;

        this.order.custom_shipment_address = false;
        this.order.shipmentAddress.address =
          destinationOffice.attributes.address ||
          order.data.attributes.shipment_address;
        this.order.date = order.data.attributes.createdAt;

        this.order.destinationOffice.id = destinationOffice.id;
        this.order.destinationOffice.code = destinationOffice.attributes.code;
        this.order.destinationOffice.originalCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.searchCode =
          destinationOffice.attributes.code;
        this.order.destinationOffice.name = destinationOffice.attributes.name;

        this.order.destinationOffice.address =
          destinationOffice.attributes.address;

        this.order.destinationOffice.currentWarehouse.id =
          destinationWarehouse.id;
        this.order.destinationOffice.currentWarehouse.code =
          destinationWarehouse.attributes.code;

        const stockistLoan = this.getSingleIncluded(
          order,
          destinationOffice.relationships['stockist-loan'].data.id
        );

        this.order.destinationOffice.restock_balance =
          stockistLoan.attributes.amount;

        this.order.originOfficeType = originOffice.attributes.office_type

        this.order.originOffice.id = originOffice.id;
        this.order.originOffice.code = originOffice.attributes.code;
        this.order.originOffice.searchCode = `${originOffice.attributes.code} (${originOffice.attributes.area_code})`;
        this.order.originOffice.originalCode =
          this.order.originOffice.searchCode;
        this.order.originOffice.name = originOffice.attributes.name;

        this.order.originOffice.currentWarehouse.id =
          originWarehouse.id;
        this.order.originOffice.currentWarehouse.code =
          originWarehouse.attributes.code;

        this.order.originOffice.area.code =
          originOffice.attributes.area_code;

        this.order.shipment_type = order.data.attributes.order_shipment;

        this.order.valid = order.data.attributes.is_valid_for_payment;

        const orderDetails = this.getIncludedByType(order, 'order-details');

        this.orderDetails = orderDetails.map((orderDetail) => ({
          id: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          originalProductCode: orderDetail.attributes.product_code,
          productName: orderDetail.attributes.product_name,
          stock: orderDetail.attributes.current_stock,
          qty: orderDetail.attributes.product_qty || null,
          originalQty: orderDetail.attributes.product_qty || null,
          price: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
        }));

        this.orderDetails.push({
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
        });
      }

      this.loadingOrder = false;
    },
    onConfirmValidate() {
      this.viewModalVisible = true;
    },
    onOrderCreated(order) {
      this.order.id = order.data.id;

      this.$router.push({
        name: 'restock-pbs.edit',
        params: {
          id: order.data.id,
        },
      });
    },
    onValidate() {
      this.validateConfirmationVisible = true;
    }
  },
  created() {
    if (this.$route.params.id) {
      this.loadOrder();
    } else {
      this.loadDestinationOffice();
      this.loadBuyerType();
    }
  },
};
</script>
