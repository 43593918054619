<template>
  <base-wrapper :loading="loading">
    <div class="space-y-6">
      <base-card title="Penjualan P3S">
        <template #action>
          <base-badge v-if="form.id" :color="form.valid ? 'green' : 'yellow'">{{
            form.valid ? 'Valid' : 'Draft'
          }}</base-badge>
        </template>
        <div
          :class="['grid gap-4', form.id ? 'sm:grid-cols-2' : 'sm:grid-cols-1']"
        >
          <base-input
            v-if="form.id"
            inset
            disabled
            label="No Penjualan"
            :value="form.code"
          />
          <base-input
            inset
            disabled
            label="Tanggal"
            :value="form.date | formatDate"
          />
        </div>
      </base-card>

      <base-card title="Barang">
        <datatable
          :scroll-x="false"
          :columns="[
            { id: 'product_code', name: 'Kode Barang' },
            { id: 'product_name', name: 'Nama Barang' },
            ...(form.valid ? [] : [{ id: 'stock', name: 'Stok' }]),
            ...(form.valid
              ? []
              : [{ id: 'product_price', name: 'Harga', theadClass: 'text-right' }]),
            { id: 'qty', name: 'Jumlah' },
            ...(form.valid
              ? []
              : [{ id: 'total_price', name: 'Total', theadClass: 'text-right' }]),
            ...(form.valid
              ? []
              : [{ id: 'action', name: 'Aksi', theadClass: 'text-center' }]),
          ]"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="(item, index) in form.items"
              :key="index"
              :class="classes.tr"
            >
              <td :class="classes.td">
                <search-stock-input
                  ref="item_product_code"
                  :disabled="form.valid"
                  :origin-warehouse="form.originWarehouse"
                  :original-search="item.originalProductCode"
                  :stock-modal-columns="['stock_loan_qty', 'price']"
                  :check-stock-real="false"
                  :check-stock-loan="true"
                  :stock-request-params="{
                    'filter[seller_office_code]': form.originOffice.code,
                    'filter[buyer_office_code]': form.destinationOffice.code,
                    'filter[area_code]': form.originOffice.area.code,
                    'filter[buyer_type_code]': 10
                  }"
                  v-model="form.items[index].productCode"
                  @change="
                    ({ stock, closeModal }) =>
                      onChangeStock(index, stock, closeModal)
                  "
                  @update:loading="onLoadingProduct"
                />
              </td>
              <td :class="classes.td">{{ item.productName }}</td>
              <td v-if="!form.valid" :class="classes.td">{{ item.stock }}</td>
              <td v-if="!form.valid" :class="[classes.td, 'text-right']">{{ item.productPrice | toCurrency }}</td>
              <td :class="classes.td">
                <input
                  v-if="item.id"
                  :disabled="form.valid"
                  ref="item_qty"
                  type="text"
                  class="w-full border-0 p-0 placeholder-gray-200 focus:border-0 focus:ring-0 sm:text-sm"
                  placeholder="Jumlah"
                  v-model="form.items[index].qty"
                  @change="onChangeQty(index)"
                />
              </td>
              <td v-if="!form.valid" :class="[classes.td, 'text-right']">{{ item.totalPrice | toCurrency }}</td>
              <td v-if="!form.valid" :class="[classes.td, 'text-center']">
                <svg
                  v-if="item.id"
                  @click="onRemove(index)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mx-auto h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </td>
            </tr>
          </template>
        </datatable>
      </base-card>

      <base-card>
        <base-input inset label="Total Harga Barang" :value="totalItemsPrice | toCurrency" disabled></base-input>
      </base-card>

      <div class="flex justify-end gap-x-2">
        <base-button
          v-if="form.id && !form.valid"
          @click="validateConfirmationVisible = true"
          >Validasi</base-button
        >
        <base-button color="white" :to="{ name: 'penjualan-pbs' }"
          >Kembali</base-button
        >
      </div>
    </div>

    <validate-confirmation
      :stock-loan-movement-id="form.id"
      :visible="validateConfirmationVisible"
      @close="validateConfirmationVisible = false"
      @confirmed="onValidateConfirmed"
    />
    <view-modal
      :stock-loan-movement-id="form.id"
      :visible="viewModalVisible"
      @close="$router.push({ name: 'penjualan-pbs' })"
    />
  </base-wrapper>
</template>

<script>
import SearchStockInput from '@/components/stock/search-stock-input.vue';
import ValidateConfirmation from '@/components/stock-loan-movement/validate-confirmation.vue';
import ViewModal from '@/components/stock-loan-movement/view-modal.vue';
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  components: { SearchStockInput, ValidateConfirmation, ViewModal },
  data() {
    return {
      disabled: false,
      form: {
        id: null,
        code: null,
        date: new Date(),
        items: [
          {
            id: null,
            productCode: null,
            originalProductCode: null,
            productName: null,
            productPrice: null,
            stock: null,
            qty: null,
            totalPrice: null,
            originalQty: null,
          },
        ],
        destinationOffice: {
          code: null
        },
        originOffice: {
          code: null,
          area: {
            code: null
          }
        },
        originWarehouse: {
          id: null,
        },
        valid: false,
      },
      loadingProduct: false,
      loadingStockLoanMovement: false,
      loadingStockLoanMovementItem: false,
      loadingStockistLoan: false,
      validateConfirmationVisible: false,
      viewModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    loading() {
      return (
        this.loadingProduct ||
        this.loadingStockLoanMovement ||
        this.loadingStockLoanMovementItem ||
        this.loadingStockistLoan
      );
    },
    totalItemsPrice() {
      return this.form.items.reduce((total, item) => total + item.totalPrice, 0)
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadStockLoanMovement(id) {
      this.loadingStockLoanMovement = true;

      const [stockLoanMovement, err] = await this.request(
        `/api/v1/stock-loan-movements/${id}`,
        {
          params: {
            include: 'warehouse,items,provider-office',
            'fields[stock-loan-movements]':
              'code,createdAt,warehouse,items,valid,provider-office',
            'fields[warehouses]': 'code',
            'fields[offices]': 'code,area_code',
            'fields[stock-loan-movement-items]':
              'product_code,product_name,qty,stock_loan_qty,stock_loan_ordered,product_price,total_price',
          },
        }
      );

      if (!err) {
        this.form.id = stockLoanMovement.data.id;
        this.form.code = stockLoanMovement.data.attributes.code;
        this.form.date = stockLoanMovement.data.attributes.createdAt;
        this.form.valid = stockLoanMovement.data.attributes.valid;

        this.form.originWarehouse.id =
          stockLoanMovement.data.relationships.warehouse.data.id;

        const providerOffice = this.getSingleIncluded(stockLoanMovement, stockLoanMovement.data.relationships['provider-office'].data.id)

        this.form.originOffice.code = providerOffice.attributes.code
        this.form.originOffice.area.code = providerOffice.attributes.area_code

        this.form.destinationOffice.code = this.me.office_code

        this.form.items = this.getIncludedByType(
          stockLoanMovement,
          'stock-loan-movement-items'
        ).map((item) => ({
          id: item.id,
          productCode: item.attributes.product_code,
          originalProductCode: item.attributes.product_code,
          productName: item.attributes.product_name,
          productPrice: item.attributes.product_price,
          stock:
            item.attributes.stock_loan_qty +
            item.attributes.qty -
            item.attributes.stock_loan_ordered,
          qty: item.attributes.qty || null,
          totalPrice: item.attributes.total_price,
          originalQty: item.attributes.qty || null,
        }));

        if (!this.form.valid) {
          this.pushEmptyItems();
        }
      }

      this.loadingStockLoanMovement = false;
    },
    async loadStockistLoan() {
      this.loadingStockistLoan = true

      const [res, err] = await this.request(`/api/v1/offices/${this.me.office_id}/stockist-loan`, {
        params: {
          'include': 'provider-office',
          'fields[stockist-loans]': 'provider-office',
          'fields[offices]': 'code,area_code'
        }
      })

      if (!err) {
        const providerOffice = this.getSingleIncluded(res, res.data.relationships['provider-office'].data.id)

        this.form.originOffice.code = providerOffice.attributes.code
        this.form.originOffice.area.code = providerOffice.attributes.area_code
      }

      this.loadingStockistLoan = false
    },
    async onChangeStock(index, stock, closeModal) {
      if (!this.form.id) {
        const [, err] = await this.storeStockLoanMovement();

        if (err) {
          return;
        }
      }

      const [res, err] = this.form.items[index].id
        ? await this.updateStockLoanMovementItem({
            id: this.form.items[index].id,
            stock,
          })
        : await this.storeStockLoanMovementItem(stock);

      if (!err) {
        this.setItem(index, res);

        if (closeModal) {
          closeModal();
        }

        this.$nextTick(() => {
          this.$refs.item_qty[index].focus();
        });
      } else {
        this.form.items[index].productCode =
          this.form.items[index].originalProductCode;
      }
    },
    async onChangeQty(index) {
      const [res, err] = await this.updateStockLoanMovementItem({
        id: this.form.items[index].id,
        qty: this.form.items[index].qty,
      });

      if (!err) {
        this.setItem(index, res);

        if (index === this.form.items.length - 1) {
          this.pushEmptyItems();

          this.$nextTick(() => {
            this.$refs.item_product_code[
              index + 1
            ].$refs.order_detail_code.focus();
          });
        }
      } else {
        this.form.items[index].qty = this.form.items[index].originalQty;
      }
    },
    onLoadingProduct(value) {
      this.loadingProduct = value;
    },
    async onRemove(index) {
      this.loadingStockLoanMovementItem = true;

      const id = this.form.items[index].id;

      const [, err] = await this.request(
        `/api/v1/stock-loan-movement-items/${id}`,
        {
          method: 'delete',
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.form.items.splice(index, 1);

        if (!this.form.items.length) {
          this.pushEmptyItems();
        }
      }

      this.loadingStockLoanMovementItem = false;
    },
    onValidateConfirmed() {
      this.validateConfirmationVisible = false;
      this.viewModalVisible = true;
    },
    pushEmptyItems() {
      this.form.items.push({
        id: null,
        productCode: null,
        originalProductCode: null,
        productName: null,
        productPrice: null,
        stock: null,
        qty: null,
        totalPrice: null,
        originalQty: null,
      });
    },
    setItem(index, res) {
      this.form.items[index].id = res.data.id;
      this.form.items[index].productCode = res.data.attributes.product_code;
      this.form.items[index].originalProductCode =
        res.data.attributes.product_code;
      this.form.items[index].productName = res.data.attributes.product_name;
      this.form.items[index].productPrice = res.data.attributes.product_price;
      this.form.items[index].stock =
        res.data.attributes.stock_loan_qty +
        res.data.attributes.qty -
        res.data.attributes.stock_loan_ordered;
      this.form.items[index].qty = res.data.attributes.qty || null;
      this.form.items[index].totalPrice = res.data.attributes.total_price;
      this.form.items[index].originalQty = res.data.attributes.qty || null;
    },
    async storeStockLoanMovement() {
      this.loadingStockLoanMovement = true;

      const [res, err] = await this.request('/api/v1/stock-loan-movements', {
        method: 'post',
        data: {
          data: {
            type: 'stock-loan-movements',
            relationships: {
              office: {
                data: {
                  type: 'offices',
                  id: this.me.office_id,
                },
              },
            },
          },
        },
      });

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.form.id = res.data.id;

        this.$router.push({
          name: 'penjualan-pbs.edit',
          params: {
            id: res.data.id,
          },
        });
      }

      this.loadingStockLoanMovement = false;

      return [res, err];
    },
    async storeStockLoanMovementItem(stock) {
      this.loadingStockLoanMovementItem = true;

      const [res, err] = await this.request(
        '/api/v1/stock-loan-movement-items',
        {
          method: 'post',
          data: {
            data: {
              type: 'stock-loan-movement-items',
              attributes: {
                qty: 0,
              },
              relationships: {
                'stock-loan-movement': {
                  data: {
                    type: 'stock-loan-movements',
                    id: this.form.id,
                  },
                },
                stock: {
                  data: {
                    type: 'stocks',
                    id: stock.id,
                  },
                },
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loadingStockLoanMovementItem = false;

      return [res, err];
    },
    async updateStockLoanMovementItem({ id, qty, stock }) {
      this.loadingStockLoanMovementItem = true;

      const [res, err] = await this.request(
        `/api/v1/stock-loan-movement-items/${id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'stock-loan-movement-items',
              id: id,
              attributes: qty
                ? {
                    qty: Number(qty.replace(/\D/g, '')),
                  }
                : {},
              relationships: stock
                ? {
                    stock: {
                      data: {
                        type: 'stocks',
                        id: stock.id,
                      },
                    },
                  }
                : {},
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loadingStockLoanMovementItem = false;

      return [res, err];
    },
  },
  created() {
    if (this.$route.params.id) {
      this.loadStockLoanMovement(this.$route.params.id);
    } else {
      this.form.originWarehouse.id = this.me.current_warehouse;
      this.form.destinationOffice.code = this.me.office_code

      this.loadStockistLoan()
    }
  },
};
</script>
