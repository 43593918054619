<template>
  <div>
    <base-modal
      :showing="visible && !validateConfirmationVisible"
      size="max-w-4xl"
      @close="onClose"
      @opened="onOpened"
    >
      <base-card
        title="Detail Penjualan P3S"
        description="Detail Penjualan P3S"
        :with-style="false"
      >
        <template #action>
          <base-badge
            :color="
              stockLoanMovement.data.attributes.valid ? 'green' : 'yellow'
            "
            >{{
              stockLoanMovement.data.attributes.valid ? 'Valid' : 'Draft'
            }}</base-badge
          >
        </template>
        <div class="space-y-6 border-t border-gray-200 py-5">
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-xs text-gray-700">No Penjualan</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockLoanMovement.data.attributes.code }}
              </dd>
            </div>
            <div class="w-full">
              <dt class="text-xs text-gray-700">Tanggal</dt>
              <dd class="mt-1 text-sm font-bold text-gray-900">
                {{ stockLoanMovement.data.attributes.createdAt | formatDate }}
              </dd>
            </div>
          </dl>
          <dl class="flex flex-row gap-x-4">
            <div class="w-full">
              <dt class="text-xs text-gray-700">Barang</dt>
              <dd class="mt-1">
                <datatable
                  :scroll-x="false"
                  :columns="[
                    { id: 'code', name: 'Kode Barang' },
                    { id: 'name', name: 'Nama Barang' },
                    { id: 'product_price', name: 'Harga Barang', theadClass: 'text-right' },
                    { id: 'qty', name: 'Jumlah', theadClass: 'text-right' },
                    { id: 'total_price', name: 'Total Harga', theadClass: 'text-right' },
                  ]"
                >
                  <template #tbody="{ classes }">
                    <tr
                      :class="classes.tr"
                      v-for="item in items"
                      :key="item.id"
                    >
                      <td :class="classes.td">
                        {{ item.attributes.product_code }}
                      </td>
                      <td :class="classes.td">
                        {{ item.attributes.product_name }}
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{ item.attributes.product_price | toCurrency }}
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{ item.attributes.qty | toCurrency }}
                      </td>
                      <td :class="[classes.td, 'text-right']">
                        {{ item.attributes.total_price | toCurrency }}
                      </td>
                    </tr>
                  </template>
                  <template v-if="items.length" #tfoot="{ classes }">
                    <tr :class="classes.tr">
                      <td colspan="3"></td>
                      <td :class="[classes.td, 'text-right']">
                        <p class="font-bold text-gray-900">Total</p>
                      </td>
                      <td :class="[classes.td, 'text-right']">{{ stockLoanMovement.data.attributes.total_price | toCurrency }}</td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
          </dl>
        </div>
        <div class="flex justify-end gap-2">
          <base-button v-if="false" color="white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            Hapus
          </base-button>
          <base-button
            v-if="!stockLoanMovement.data.attributes.valid"
            color="white"
            @click="$emit('edit')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            Edit
          </base-button>
          <base-button
            v-if="!stockLoanMovement.data.attributes.valid"
            @click="validateConfirmationVisible = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            Validasi
          </base-button>
        </div>
      </base-card>
    </base-modal>

    <validate-confirmation
      :stock-loan-movement-id="stockLoanMovement.data.id"
      :visible="validateConfirmationVisible"
      @close="onCloseValidateConfirmation"
      @confirmed="onValidateConfirmed"
    />

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import ValidateConfirmation from '@/components/stock-loan-movement/validate-confirmation.vue';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    ValidateConfirmation,
  },
  props: {
    visible: Boolean,
    stockLoanMovementId: String,
  },
  emits: ['close', 'edit', 'validated'],
  data() {
    return {
      loading: false,
      reload: true,
      stockLoanMovement: {
        data: {
          attributes: {},
          relationships: {
            items: {
              data: [],
            },
          },
        },
        included: [],
      },
      validateConfirmationVisible: false,
    };
  },
  computed: {
    items() {
      return this.getIncludedByType(
        this.stockLoanMovement,
        'stock-loan-movement-items'
      )
    }
  },
  methods: {
    async onOpened() {
      if (this.reload) {
        this.loading = true;

        const [res, err] = await this.request(
          `/api/v1/stock-loan-movements/${this.stockLoanMovementId}`,
          {
            params: {
              include: 'items',
              'fields[stock-loan-movements]': 'code,createdAt,items,valid,total_price',
              'fields[stock-loan-movement-items]':
                'product_code,product_name,qty,product_price,total_price',
            },
          }
        );

        if (!err) {
          this.stockLoanMovement = res;
        }

        this.loading = false;
      }
    },
    onClose() {
      this.reload = true;
      this.$emit('close');
    },
    onCloseValidateConfirmation() {
      this.reload = false;
      this.validateConfirmationVisible = false;
    },
    onValidateConfirmed() {
      this.reload = true;
      this.validateConfirmationVisible = false;

      this.$emit('validated');
    },
  },
};
</script>
